iframe {
  pointer-events: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.interest_graph_sect {
  font-size: x-small;
}

.ruleGroup-header select {
  width: 75px !important;
}

.form-check-label {
  padding: 2px 0px 0px 4px;
}

.justify_center {
  justify-content: center;
}

.loan_search .css-4ljt47-MenuList {
  max-height: 250px !important;
}

.hide {
  visibility: hidden;
}

.ace_scroller.ace_scroll-left {
  box-shadow: none !important;
}

.loan-search-input {
  border: 1px solid #ddd;
  max-width: 400px;
  height: 45px;
  padding-right: 78px
}

.loan-search-input:focus {
  border: 1px solid var(--border-color) !important;
  box-shadow: 0px 0px 6px var(--box-shadow-color) !important;
}

a:focus-visible {
  background-color: var(--base-color) !important ;
  box-sizing: border-box !important;
  outline: none !important;
  box-shadow: 0px 0px 6px var(--box-shadow-color) !important ;
  border-color: var(--base-color) !important ;
  border-radius: 5px !important;
}

h3 span:focus-visible {
  background-color: var(--base-color) !important ;
  box-sizing: border-box !important;
  outline: none !important;
  box-shadow: 0px 0px 6px var(--box-shadow-color) !important ;
  border-color: var(--base-color) !important ;
  border-radius: 5px !important;
  padding: 4px !important;
  margin-left: -4px !important;
}

button:focus-visible {
  background-color: var(--base-color) !important ;
  box-sizing: border-box !important;
  outline: none !important;
  box-shadow: 0px 0px 6px var(--box-shadow-color) !important ;
  border-color: var(--base-color) !important ;
  border-radius: 5px !important;
}

.footer_links li a:focus-visible {
  color: black !important;
}

.tooltip_close:focus-visible {
  color: black;
}

.nav-link:hover, .nav-link:focus {
  color:#9A9A9A ;
}

.loan-search-input:hover {
  border: 1px solid var(--border-color) !important;
  box-shadow: 0px 0px 6px var(--box-shadow-color) !important;
}

.search-btn-ls {
  border: none;
  height: 35px;
  margin-top: 3px;
  border-bottom: none !important;
}

.search-btn-ls:focus {
  box-shadow: none !important;
}

.local-search {
  width: 168px !important;
  display: inline-block;
   /* margin-top: 0.5em;  */
  float: right; 
  margin-right: 1rem;   
  border-radius: 10px;
}

@media (max-width: 575.98px) {
  .local-search {
    margin-top: 0 !important; 
    font-size: 13px !important;
    padding: 4px 8px;
    width: 185px !important;
  }
}

.local-search:focus {
  border: 1px solid var(--border-color) !important;
  box-shadow: 0 0 6px var(--box-shadow-color) !important;
}

.local-search:hover {
  border: 1px solid var(--border-color) !important;
  box-shadow: 0 0 6px var(--box-shadow-color) !important;
}

.w350 {
  width: 350px !important;
}

.result-icon {
  float: right;
  margin-top: 13px;
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}

.submit-btn {
  margin-right: 1rem !important;
}

.assignment_claim .logi-embed .zd-main .zd-main-section {
  z-index: 9 !important;
}

.assignment_claim .logi-embed .zd-main-section.dashboard-widgets .dashboard .dashboard-header {
  margin-top: 15px !important;
}

.pos-fixed {
  position: relative;
  width: 100%;
}

.assignment_claim .logi-embed .zd-main .zd-main-section.dashboard-widgets {
  margin-top: 50px !important;
}

.profile-box {
  position: relative;
}

.profile-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.edit-box {
  display: none !important;
  width: 100%;
  height: 200px;
  margin: -200px auto 0;
  border-radius: 10px;
}

.profile-box:hover .edit-box {
  display: block !important;
}

.edit-profile {
  position: relative;
  top: 85%;
  left: auto;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0px 20px;
  height: 20px;
  color: black;
  border-radius: 3px;
  cursor: pointer !important;
}


.content_section {
  background-image: none !important;
}

.input-field {
  position: relative;
  width: 400px;
}

.customized-input {
  top: 13%;
  position: absolute;
  border: none;
  border-bottom: 1px solid #006b7f;
  width: 25%;
  padding: 5px 5px 0;
}

.transparent-background {
  background-color: transparent !important;
}

.additional-tab .loading_box {
  position: relative !important;
}

.error-message {
  color: red;
  font-size: 14px;
}

.user-group-select {
  height: 250px !important;
  border-radius: 4px;
  overflow: auto !important;
  padding-right: 60px !important;
  box-sizing: border-box !important;
  box-shadow: 0 0 8px #19C19C !important;
}

.user-group-label {
  color: grey;
  font-size: 14px;
  margin-left: 3px;
  margin-bottom: -10px;
}

.sticky-menubar {
  margin-left: -90px !important;
  margin-right: 16px !important;
  z-index: 100 !important;
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  padding-bottom: 2px !important;
}

.font-color {
  color: black !important
}

.ag-checked {
  background-color: #006b7f !important;
}

.justify-end {
  justify-content: end !important;
}

.pg-viewer-wrapper {
  min-height: 500px !important;

}

.viewer-container .pg-viewer-wrapper {
  overflow-y: hidden !important;
}

.viewer-container .react-grid-Grid {
  min-height: 1200px !important;
}
.file-container {
  padding: 1rem;
  width: 100%;
  height: 100%;
}
.react-grid-HeaderCell-sortable{
  cursor: default!important;
  pointer-events: none !important; 

}
.file-container canvas {
  width: 100%
}

.disabled{
  pointer-events: auto !important;
  cursor:not-allowed !important;
}

.enabled{
  cursor:pointer;
}

.fileUpload{
  
  white-space: pre-wrap !important; 
  word-break: break-all !important;
}
.input-box{
 
  border: 1px dotted grey;
  height: 37px;
  width:280px;
  border-radius: 5px; 
  padding: 2px 8px;
  box-sizing: border-box;
}

.filename-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
  
.filename-text {
  text-overflow: ellipsis;
  max-width: 10em;
  white-space: nowrap;
  overflow: hidden;
  }
  
.filename-ext {
  white-space: nowrap;
  }

  
.file-upload-input{
   flex: 1; 
   border: 1px dotted grey;
   border-radius:4px; 
   padding: 6px;
  }
.fixed-position {
   width: 80%;
   position: fixed !important;
 }
 div.viewer-toolbar-right>div:nth-child(2),div.viewer-toolbar-right>div:nth-child(3),div.viewer-toolbar-right>div:last-child {
  display: none;
}
div.viewer-sidebar-tabs>div:nth-child(2),div.viewer-sidebar-tabs>div:last-child{
  display: none;
}
.recharts-default-legend li {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap  !important;
}
.error-text{
   display: flex;
   align-items: center;
   justify-content: center;
   height: 34px;
   margin-top: -10px;
 }

 #error-text {
  margin-top: 5px !important;
 }

  .ct_form_field .form-control:disabled {
  opacity: 0.3;
  cursor: not-allowed;
 }

 .rule_group_validation p,h4{
 margin-bottom: 2px;
 color:  #0A6522 ;
 font-size: 1rem;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
line-height: 1.5;
 letter-spacing: 0.00938em
 }
/* Css for dashBoard Library and Report web */
 
 .dashboard-search-container{
  display: flex;
  align-items: center;
}
/* Css for dashBoard Library and searchBox Mobile */ 
@media (max-width: 767px) {
 .m-search-box {
    display: flex;
    margin-bottom: 15px;
  }
 .m-main-contianer{
   display: flex;
   flex-direction: column;
  }
  .dashboard-search-container{
    justify-content: space-between;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .m-search-box {
    display: none;
  }
  .dashboard-search-container{
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 468px) {
  /* CSS for mobile devices */
  .fileUpload1 {
   margin-bottom: 12px;
  }
  .bp3-dialog.css-13ipggk {
    width: 320px!important;
}
.bp3-form-group.bp3-inline.css-vvbwfl {
display: flex !important;
flex-direction: column !important;
}
 .bp3-input-group.css-1ias2p4 {
  width:100% !important
} 
.bp3-form-group.bp3-inline.css-19zn5rm {
  display: flex !important;
flex-direction: column !important;
}

.bp3-form-content {
  width:100% !important
}
}


.rule-text-center{
  margin-top: 6rem !important;
}

@media (max-width: 1050px){
  .rule {
         flex-direction: column !important;
      align-items : stretch !important;
     }
 }

 @media (max-width: 363px){
  .ruleGroup .ruleGroup-header{
         flex-direction: column !important;
          align-items : stretch !important;
     }
 }

 .queryBuilder-disabled:hover  {
  border-color: null;
  cursor:not-allowed;
 }
 
 .queryBuilder-disabled .form-select:hover{
  border-width : 0;
  box-shadow: none !important;
  cursor:not-allowed;
 }
 /* Responsive Css for MyProfile Screen and login verbiage */

 @media (max-width: 468px) {
   .login-SnackBar{
    left: 5%!important;
    right: 5%!important;
   }
  .p_info_edit_email_col1{
    width:100%!important;
    padding: 5px 10px!important;
  }
.p_info_edit_email_col2{
  width:100%!important;
  padding: 0px 10px!important;
  }
  .p_info_edit_block p{
   padding:  5px 10px 0px 10px!important;
  }
  .p_info_edit_block-m{
    display: flex;
    flex-direction: column;
  }
}
.p_info_edit_mobile{
  display: table-cell;
  padding: 8px 10px;
  position: relative;
  font-weight: bold
}

/* responsive Css for grid pagination*/
@media only screen and (max-width: 408px) {
  .ag-paging-panel {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
/*Responsive Css for Documents Pdf  */
  @media only screen and (max-width: 480px) {
    .m-documents {
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
    justify-content: center!important;
  }
 .viewer-layout-toolbar.viewer-toolbar.viewer-toolbar-left.viewer-toolbar-item{
   padding:0px!important
  }
 .viewer-toolbar-item .viewer-toolbar-current-page-input{
   padding:2px!important;
   width:28px!important;
 } 
 .viewer-toolbar-item .viewer-button{
   padding:6px!important;
 }
 .file-container{
    padding:8px!important;
  }  
}
.documents{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.fixed-rule-popup{
  left:1010px !important;
}

.fixed-rule-popup2{
 left:740px !important;
}
.bp3-text-content{
  font-size: 14px !important;
  font-weight: 300 !important; 
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  color: grey !important;
  padding:  0px 0px 0px 5px !important;
}

.changeEmail button{
  padding-left: 7px;
  }

.zdView-DashboardHeader{
  padding-right: 45px !important;
}
.css-1gq5iy3 > .bp3-popover-arrow{
  left: 76px !important;
}

@media (min-width: 992px) 
{
.normal-screen {
    flex: 0 0 auto;
    width: 32%;
}
}

@media (min-width: 1400px) 
{
.normal-screen {
    flex: 0 0 auto;
    width: 18%;
}
}
@media (min-width: 992px) 
{
.normalscreen {
    flex: 0 0 auto;
    width: 92%;
}
}

@media (min-width: 1400px) 
{
.normalscreen {
    flex: 0 0 auto;
    width: 55%;
}
}

.re-enter-new-password
{
  margin-right: 22px !important;
}
#logi-modal-root .css-xvycbo .bp3-popover-content, #logi-embed-theme-modern .css-xvycbo .bp3-popover-content, .logi-embed .css-xvycbo .bp3-popover-content {
  width: 250px !important;
} 
